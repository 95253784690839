import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  public pageTitle: string;
  // title = 'Bob de bot';

  constructor(private titleService: Title) {
    this.titleService.setTitle(environment.name);
  }
  ngOnInit(): void {

  }
}


