import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { environment } from '../environments/environment';
import { HttpClientModule } from '@angular/common/http';
import { MatButtonModule } from '@angular/material/button';
import { MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatMenuModule } from '@angular/material/menu';
import {
  MatFormFieldModule,
  MatFormFieldDefaultOptions,
  MAT_FORM_FIELD_DEFAULT_OPTIONS,
} from '@angular/material/form-field';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { FlexLayoutModule } from '@angular/flex-layout';
import { MatInputModule } from '@angular/material/input';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatBadgeModule } from '@angular/material/badge';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NavBarComponent } from './nav-bar/nav-bar.component';
import { LoginComponent } from './auth/login/login.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFireAuthGuardModule } from '@angular/fire/compat/auth-guard';

import { MatNativeDateModule } from '@angular/material/core';
import { MatTabsModule } from '@angular/material/tabs';
import { QRCodeModule } from 'angularx-qrcode';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { MatChipsModule } from '@angular/material/chips';
import { MatSelectModule } from '@angular/material/select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRadioModule } from '@angular/material/radio';

import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { ColorPickerModule } from 'ngx-color-picker';
import { MentionModule } from 'angular-mentions';

import { RegistrationComponent } from './auth/registration/registration.component';
import { TasksComponent } from './tasks/tasks.component';
import { VolunteersComponent } from './volunteers/volunteers.component';
import { EditVolunteerComponent } from './volunteers/edit-volunteer/edit-volunteer.component';
import {
  TaskDetailsComponent,
  ApproveDialogComponent,
  InviteDialogComponent,
  SaveDialogComponent,
} from './tasks/task-details/task-details.component';
import { ConstantsService } from './common/services/constants.service';
import { EditTaskComponent } from './tasks/edit-task/edit-task.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { TagsComponent } from './tags/tags.component';
import { EditTagComponent } from './tags/dialog/edit-tag/edit-tag.component';
import { ImportExportComponent } from './import-export/import-export.component';
import { SettingsComponent } from './settings/settings.component';
import { UpgradeDialogComponent } from './nav-bar/dialogs/upgrade-dialog/upgrade-dialog.component';
import { ScheduleTaskComponent } from './tasks/edit-task/dialogs/schedule-task/schedule-task.component';
import { ChangeOrganisationComponent } from './nav-bar/dialogs/change-organisation/change-organisation.component';
import { PostsComponent } from './posts/posts.component';
import { ModeratePostsComponent } from './posts/dialogs/moderate-posts/moderate-post.component';

const appearance: MatFormFieldDefaultOptions = {
  appearance: 'outline',
};

@NgModule({
    declarations: [
        AppComponent,
        NavBarComponent,
        LoginComponent,
        RegistrationComponent,
        TasksComponent,
        VolunteersComponent,
        TaskDetailsComponent,
        EditTaskComponent,
        ApproveDialogComponent,
        InviteDialogComponent,
        ConfirmDialogComponent,
        TagsComponent,
        EditTagComponent,
        EditVolunteerComponent,
        SaveDialogComponent,
        ImportExportComponent,
        SettingsComponent,
        UpgradeDialogComponent,
        ScheduleTaskComponent,
        ChangeOrganisationComponent,
        PostsComponent,
        ModeratePostsComponent,
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFireAuthModule,
        AngularFireStorageModule,
        AngularFirestoreModule,
        AngularFireAuthGuardModule,
        MentionModule,
        MatButtonModule,
        MatIconModule,
        MatListModule,
        MatSidenavModule,
        MatToolbarModule,
        MatMenuModule,
        MatRadioModule,
        MatRippleModule,
        MatTabsModule,
        MatCardModule,
        MatTooltipModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatCheckboxModule,
        MatSnackBarModule,
        MatButtonToggleModule,
        MatAutocompleteModule,
        ScrollingModule,
        MatProgressBarModule,
        MatSlideToggleModule,
        MatBadgeModule,
        QRCodeModule,
        FlexLayoutModule,
        MatFormFieldModule,
        MatCardModule,
        MatGridListModule,
        FlexLayoutModule,
        MatInputModule,
        FormsModule,
        AngularFireDatabaseModule,
        MatChipsModule,
        MatSelectModule,
        ReactiveFormsModule,
        MatDialogModule,
        ColorPickerModule,
        NgxMatSelectSearchModule,
    ],
    providers: [
        ConstantsService,
        {
            provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
            useValue: appearance,
        },
        {
            provide: MAT_DATE_LOCALE,
            useValue: 'nl-NL',
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
