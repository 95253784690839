import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  ReplaySubject,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  map,
  first,
  take,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Timestamp } from '@firebase/firestore-types';
import { ConstantsService } from './../common/services/constants.service';
import { PlatformLocation } from '@angular/common';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { EditTagComponent } from '../tags/dialog/edit-tag/edit-tag.component';
import { ImportExportComponent } from '../import-export/import-export.component';
import * as XLSX from 'xlsx';

export interface Tag {
  color: string;
  name: string;
  tagQuestion: string;
}

export interface TagId extends Tag {
  id: string;
}

export interface User {
  name?: string;
  organisationRef?: DocumentReference;
  email?: string;
  id?: string;
  organisationId?: string;
}
export interface UserId extends User {
  id: string;
}

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss'],
})
export class TagsComponent implements OnInit {
  disabled: boolean;
  tagsCollection: AngularFirestoreCollection<Tag>;
  tags: Observable<TagId[]>;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  public filteredTags: ReplaySubject<TagId[]> = new ReplaySubject<TagId[]>(1);
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  totalTags: number;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  importedData: Subject<TagId[]> = new ReplaySubject<TagId[]>();
  selectedFile: File;
  worksheet: any;
  storeData: any;

  constructor(
    private router: Router,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private constant: ConstantsService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar
  ) {}

  ngOnInit() {
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.userDoc = this.afs.doc<User>('users/' + user.uid);
        // console.log('currentUser', this.afAuth.auth.currentUser);
        this.user = this.userDoc.valueChanges().pipe(
          map((userData) => {
            // console.log('User =>', userData);
            if (!userData) {
              userData = {};
            }
            userData.id = user.uid;
            return userData;
          })
        );
        this.user.subscribe((userData) => {
          console.log('userData', userData);
          this.tagsCollection = this.afs.collection<Tag>(
            'organisations/' + userData.organisationRef.id + '/tags',
            (ref) => ref.orderBy('name')
          );
          this.tags = this.tagsCollection.snapshotChanges().pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as Tag;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          );
          this.tags.subscribe((val) => {
            console.log('tags', val);
          });
          const combinedObservable = combineLatest(
            this.tags,
            this.searchQueryChanged
          );
          combinedObservable
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((values) => {
              console.log('values', values);
              const tags = values[0];
              const searchQuery = values[1];
              // const filters = {
              //   showHidden: values[2],
              //   orderBy: values[3],
              //   orderDirection: values[4]
              // };
              const filteredTags = tags.filter((item) =>
                this.checkFilters(item)
              );
              // this.orderDirection = 'desc';
              // console.log('orderBy', filters.orderBy);
              // filteredVehicles = filteredVehicles.sort((a, b) => {
              //   if (a[filters.orderBy] > b[filters.orderBy]) {
              //     return 1;
              //   } else {
              //     return -1;
              //   }
              //   // return 0;
              // });
              // if (filters.orderDirection === 'desc') {
              //   filteredVehicles.reverse();
              // }
              console.log('filteredTags', filteredTags);
              this.totalTags = filteredTags.length;
              this.filteredTags.next(filteredTags);
              // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
            });
          this.searchQueryChanged.next('');
        });
      }
    });
  }

  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(tag) {
    // console.log('user', user);
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (tag.name) {
        if (tag.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
          passesSearchFilter = true;
        }
      }
      if (tag.tagQuestion) {
        if (
          tag.tagQuestion.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      // console.log('user passes filter:', user);
      return tag;
    }
  }

  editTag(tag?) {
    const dialogRef = this.dialog.open(EditTagComponent, {
      width: '400px',
      data: { tagsCollection: this.tagsCollection, tag },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed with result:', result);
      if (result) {
      }
    });
  }

  deleteTag(tag, orgRefId) {
    console.log('delete tag', tag);
    console.log('orgRefId', orgRefId);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Categorie verwijderen',
        text: `Weet je zeker dat je de categorie ${tag.name} wil verwijderen?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      if (result) {
        await this.afs
          .doc<Tag>('organisations/' + orgRefId + '/tags/' + tag.id)
          .delete();
        this._snackBar.open('Categorie verwijderd', '', {
          duration: 5000,
        });
      }
    });
  }
  uploadedFile(event) {
    console.log('event', event);
    console.log('uploading file');
    this.selectedFile = event.target.files[0];
    this.readExcel();
  }
  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result === 'Export') {
        this.exportTableToExcel();
      }
      if (result === 'Import') {
        this.uploader.nativeElement.click();
      }
    });
  }
  readExcel() {
    try {
      const readFile = new FileReader();

      const spreadsheet = {};
      readFile.onload = (e) => {
        try {
          this.storeData = readFile.result;
          const data = new Uint8Array(this.storeData);
          const arr = new Array();
          for (let i = 0; i !== data.length; ++i) {
            arr[i] = String.fromCharCode(data[i]);
          }
          const bstr = arr.join('');
          const workbook = XLSX.read(bstr, { type: 'binary' });
          const firstSheetName = workbook.SheetNames[0];
          this.worksheet = workbook.Sheets[firstSheetName];

          Object.keys(this.worksheet).forEach((key) => {
            if (key !== '!ref' && key !== '!margins') {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] = this.worksheet[key].w;
            }
          });
          const columnNames = spreadsheet[1];
          Object.keys(columnNames).forEach((key) => {
            key = key;
            const val = columnNames[key].toLowerCase();
            switch (val) {
              default:
                delete columnNames[key];
                break;
              case 'naam':
                columnNames[key] = 'name';
                break;
              case 'kleur':
                columnNames[key] = 'color';
                break;
              case 'uitvraagbericht':
                columnNames[key] = 'tagQuestion';
                break;
            }
          });
          delete spreadsheet[1];
          const importedData = [];
          Object.keys(spreadsheet).forEach((key) => {
            const rowObj = {};
            Object.keys(spreadsheet[key]).forEach((colKey) => {
              const colName = columnNames[colKey];
              // console.log(spreadsheet[key][colKey]);
              // console.log('colName', colName);
              if (colName) {
                if (colName === 'name') {
                  spreadsheet[key][colKey] = spreadsheet[key][colKey];
                }
                if (colName === 'color') {
                  spreadsheet[key][colKey] = spreadsheet[key][colKey];
                }
                if (colName === 'tagQuestion') {
                  spreadsheet[key][colKey] = spreadsheet[key][colKey];
                }
                rowObj[colName] = spreadsheet[key][colKey];
              }
            });
            console.log('rowObj', rowObj);
            importedData.push(rowObj);
          });
          console.log('importedData', importedData);
          this.importedData.next(importedData);
          this.fileChangeListener();
        } catch (err) {
          console.error(err);
          this._snackBar.open(
            'fout bij inladen bestand, controleer gegevens en probeer opnieuw.',
            '',
            {
              duration: 5000,
            }
          );
          throw err;
        }
      };
      readFile.readAsArrayBuffer(this.selectedFile);
    } catch (err) {
      this._snackBar.open(
        'onbekende fout, controleer bestand en probeer opnieuw.',
        '',
        {
          duration: 5000,
        }
      );
      throw err;
    }
  }
  async exportTableToExcel() {
    // const volunteersRef = this.afs.collection<Volunteer>('organisations/' + userData.organisationRef.id + '/volunteers');
    const exportArray = [];
    // const volunteersObservable = await this.volunteersCollection.get();
    this.filteredTags.forEach((items) => {
      // console.log('vouchers', vouchers);
      items.forEach((itemDoc) => {
        // const volunteer = volunteerDoc.data() as Volunteer;
        const exportObj: any = {};
        exportObj.Naam = itemDoc.name ? itemDoc.name : '';
        exportObj.Kleur = itemDoc.color ? itemDoc.color : '';
        exportObj.Uitvraagbericht = itemDoc.tagQuestion
          ? itemDoc.tagQuestion
          : '';
        console.log('exportObj', exportObj);
        exportArray.push(exportObj);
      });
    });
    if (exportArray.length > 0) {
      console.log('vouchers', exportArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Tags');

      // /* save to file */
      XLSX.writeFile(wb, 'tags.xlsx');
    } else {
      // Nothing to export
    }
  }
  fileChangeListener() {
    this.tags = this.tagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    const combinedObservable = combineLatest(this.tags, this.importedData);
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((res: [TagId[], Array<any>]) => {
        console.log('res', res);
        const existingObj = res[0];
        const importedObj = res[1];
        if (importedObj === null) {
          return;
        }
        // check for existingVolunteers with importedVolunteers' data, update their info.
        // create new entry for all newly imported volunteers, process account creation in functions trigger.
        importedObj.forEach(async (objData) => {
          console.log('objData', objData);
          let name;
          let tagQuestion;
          let color;
          // this section looks for a recognized column title and matches it with the correct userData
          if (objData.name) {
            name = objData.name;
          }
          if (objData.tagQuestion) {
            tagQuestion = objData.tagQuestion;
          }
          if (objData.color) {
            color = objData.color;
          } else {
            color = '';
          }
          const importData: Tag = { name, tagQuestion, color };
          //
          const existingData = existingObj.find((element) => {
            return element.name === importData.name;
          });
          console.log('existingData', existingData);
          if (existingData) {
            // update existing doc

            await this.tagsCollection
              .doc(existingData.id)
              .set(importData, { merge: true });
          } else {
            // create new doc
            const docId = this.afs.createId();
            await this.tagsCollection.doc(docId).set(importData);
          }
          this.importedData.next(null);
        });

        this._snackBar.open('De categorieën zijn geimporteerd', '', {
          duration: 5000,
        });
      });
  }
}
