import { Component, OnInit, ViewChild } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
// import { User, TownshipUser, Township, Organisation, Address } from '../interfaces';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import * as XLSX from 'xlsx';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as firestore from 'firebase/firestore';
import { Router } from '@angular/router';
import { environment } from '../../environments/environment';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';

export interface Organisation {
  id?: string;
  name: string;
  inviteMessage: string;
  requiresFullName: boolean;
  postals: [];
  // requiresPostal: boolean;
}

export interface User {
  email?: string;
  organisationRef?: DocumentReference;
}

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnInit {
  mentionItems: string[] = ['Naam', 'Organisatie'];
  settingsForm: FormGroup;
  userId: Observable<string>;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  organisationDoc: AngularFirestoreDocument<Organisation>;
  organisation: Organisation;
  // townshipUserDoc: AngularFirestoreDocument<TownshipUser>;
  // townshipUser: Observable<TownshipUser>;
  // addressEmptyOnLoad: boolean;
  saving: boolean;
  env = environment;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA, SPACE];

  constructor(
    public db: AngularFirestore,
    public afAuth: AngularFireAuth,
    private snackBar: MatSnackBar,
    private router: Router
  ) {}

  ngOnInit() {
    this.settingsForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      inviteMessage: new FormControl('', [
        Validators.required,
        Validators.maxLength(148),
      ]),
      requiresFullName: new FormControl(false),
      requiresPostal: new FormControl(false),
      postals: new FormControl([]),
    });
    this.afAuth.user.forEach((user) => {
      console.log('user', user);
      this.userDoc = this.db.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges();
      this.user.forEach(async (userDoc) => {
        if (userDoc.organisationRef) {
          console.log('organisation', userDoc.organisationRef.path);
          this.organisationDoc = this.db.doc<Organisation>(
            userDoc.organisationRef
          );
          // this.townshipUserDoc = this.db.doc<TownshipUser>('township/' + userDoc.township + '/users/' + user.uid);
          this.organisation = (
            await this.organisationDoc.ref.get()
          ).data() as Organisation;
          // this.addressEmptyOnLoad = (await this.townshipDoc.collection('addresses').ref.limit(1).get()).empty;
          this.settingsForm.patchValue(this.organisation);
          console.log('this.organisation', this.organisation);
        }
      });
    });
  }

  add(event: MatChipInputEvent) {
    const input = event.input;
    const value = (event.value || '').trim().replace(/\D/g, '');
    // Add new input to courseIds control value
    if (value.length === 4) {
      if (input) {
        input.value = '';
      }
      this.remove(value);
      const postals = this.settingsForm.value.postals;
      postals.push(value);
      this.settingsForm.controls.postals.setValue(postals.sort());
    } else {
      this.snackBar.open('Dit zou 4 cijfers moeten bevatten.', '', {
        duration: 5000,
      });
    }
  }

  remove(value: string) {
    // console.log('value', value);
    const postals: string[] = this.settingsForm.value.postals;
    // console.log('postals', postals);
    if (postals.indexOf(value) !== -1) {
      postals.splice(postals.indexOf(value), 1);
      // Remove element from control value array
      this.settingsForm.controls.postals.setValue(postals);
    }
  }

  copyLinkToClipboard(type) {
    let link;
    if (!this.env.production) {
      link = 'https://dev-bot-invite.wemaron.nl';
    } else {
      switch (this.env.env) {
        default:
          link = 'https://invite.hallovriend.nl';
          break;
        case 'bob':
          link = 'https://invite.bobdebot.nl';
          break;
        case 'hallobot':
          link = 'https://invite.hallobot.nl';
          break;
      }
    }
    if (type == 'signup') {
      link = link + '/signup/';
    } else {
      link = link + '/invite/';
    }
    link = link + this.organisationDoc.ref.id;
    const el = document.createElement('textarea');
    el.value = link;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    this.snackBar.open('Link is gekopieerd.', '', {
      duration: 3000,
    });
  }

  async save() {
    if (this.saving) {
      return;
    }
    this.saving = true;
    const saveObj = { ...this.settingsForm.value } as Organisation;
    console.log('saveObj', saveObj);

    // Make sure no "null" values are attempting to be saved, also if something is null it will be deleted from the database.
    Object.keys(saveObj).forEach((key) => {
      if (saveObj[key] == null) {
        saveObj[key] = firestore.deleteField();
      }
    });
    await this.organisationDoc.set(saveObj, { merge: true });
    this.saving = false;
    this.snackBar.open('Je instellingen zijn opgeslagen.', '', {
      duration: 5000,
    });
  }
}
