import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  NgZone,
} from '@angular/core';
import { Router } from '@angular/router';
import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  ReplaySubject,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import {
  map,
  first,
  take,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Timestamp } from '@firebase/firestore-types';
import { ConstantsService } from './../common/services/constants.service';
import { PlatformLocation } from '@angular/common';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as XLSX from 'xlsx';

export interface Task {
  name: string;
  requiredVolunteers: number;
  approvedVolunteers: number;
  message: string;
  address: string;
  postal: string;
  tags: any;
  date: Timestamp;
  volenteers: [];
  acceptedResponse: string;
  rejectedResponse: string;
  manualConfirm: boolean;
  messageType: string;
  noResponse: string;
  yesResponse: string;
  rejectedVolunteers?: number;
  noReaction?: number;
  volunteersSaidYes?: number;
  volunteersSaidNo?: number;
  invitedVolunteers?: number;
}
export interface TaskId extends Task {
  id: string;
}
export interface Tag {
  name: string;
  color: string;
}
export interface TagId extends Tag {
  id: string;
}
export interface User {
  name?: string;
  organisationRef?: DocumentReference;
  email?: string;
  id?: string;
  organisationId?: string;
}
export interface UserId extends User {
  id: string;
}

@Component({
  selector: 'app-tasks',
  templateUrl: './tasks.component.html',
  styleUrls: ['./tasks.component.scss'],
})
export class TasksComponent implements OnInit {
  disabled: boolean;
  tasksCollection: AngularFirestoreCollection<Task>;
  tasks: Observable<TaskId[]>;
  public filteredTasks: ReplaySubject<TaskId[]> = new ReplaySubject<TaskId[]>(
    1
  );
  tagsCollection: AngularFirestoreCollection<Tag>;
  tags: Observable<TagId[]>;
  fullItems = [];
  // user: import('./../common/services/constants.service').User;
  subscription: Subscription;
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  organisationId: string;
  orderBy = 'date';
  orderByChanged: Subject<string> = new Subject<string>();
  orderDirection = 'desc';
  orderDirectionChanged: Subject<string> = new Subject<string>();
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  totalTasks: number;
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  fileUploaded: File;
  organisationsAmountVolunteers: number;
  amountToShow = 20;
  amountToShowIncrease = 20;
  amountToShowChanged: Subject<number> = new Subject<number>();

  // subscribe: Subscribable;
  constructor(
    private router: Router,
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private constant: ConstantsService,
    public dialog: MatDialog,
    private _snackBar: MatSnackBar,
    private zone: NgZone
  ) {}

  async ngOnInit() {
    this.afAuth.user.subscribe((user) => {
      if (user) {
        this.userDoc = this.afs.doc<User>('users/' + user.uid);
        this.user = this.userDoc.valueChanges().pipe(
          map((userData) => {
            // console.log('User =>', userData);
            if (!userData) {
              userData = {};
            }
            userData.email = user.email;
            userData.id = user.uid;
            if (userData.organisationRef) {
              this.organisationId = userData.organisationRef.id;
            }
            return userData;
          })
        );
        this.user.subscribe(async (userData) => {
          console.log('userData', userData);
          this.tasksCollection = this.afs.collection<Task>(
            'organisations/' + userData.organisationRef.id + '/tasks',
            (ref) => ref.orderBy('date', 'desc')
          );
          const amountOfVolunteers = (
            await this.afs
              .collection(
                `organisations/${userData.organisationRef.id}/volunteers`,
                (ref) => ref.where('hasGivenPermission', '==', true)
              )
              .get()
              .toPromise()
          ).docs;
          this.tasks = this.tasksCollection.snapshotChanges().pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as Task;
                const id = a.payload.doc.id;
                if (data.volunteersSaidYes === undefined) {
                  data.volunteersSaidYes = 0;
                }
                if (data.volunteersSaidNo === undefined) {
                  data.volunteersSaidNo = 0;
                }
                if (
                  data.invitedVolunteers === undefined ||
                  data.invitedVolunteers < 0
                ) {
                  data.invitedVolunteers = 0;
                }
                if (data.approvedVolunteers === undefined) {
                  data.approvedVolunteers = 0;
                }

                return { id, ...data };
              })
            )
          );
          this.tagsCollection = this.afs.collection<Tag>(
            'organisations/' + userData.organisationRef.id + '/tags'
          );
          this.tags = this.tagsCollection.snapshotChanges().pipe(
            map((actions) =>
              actions.map((a) => {
                const data = a.payload.doc.data() as Tag;
                const id = a.payload.doc.id;
                return { id, ...data };
              })
            )
          );

          const combinedObservable = combineLatest([
            this.tasks,
            this.tags,
            this.amountToShowChanged,
            this.searchQueryChanged,
          ]);
          combinedObservable
            .pipe(debounceTime(300), distinctUntilChanged())
            .subscribe((values) => {
              console.log('values', values);
              const task = values[0];
              const tag = values[1];
              const amountToShow = values[2];
              const searchQuery = values[3];

              const filteredTasks = task.filter((item) =>
                this.checkFilters(item, searchQuery)
              );
              filteredTasks.forEach((value) => {
                const taskTagCollection = this.afs.collection<Tag>(
                  'organisations/' +
                    userData.organisationRef.id +
                    '/tasks/' +
                    value.id +
                    '/tags',
                  (ref) => ref.orderBy('index', 'asc')
                );
                const taskTag = taskTagCollection.snapshotChanges().pipe(
                  map((actions) =>
                    actions.map((a) => {
                      const data = a.payload.doc.data() as Tag;
                      const id = a.payload.doc.id;
                      return { id, ...data };
                    })
                  )
                );
                taskTag.subscribe((val) => {
                  value.tags = val;
                });
              });
              console.log('filteredTasks', filteredTasks);
              this.totalTasks = filteredTasks.length;
              this.filteredTasks.next(filteredTasks);
              this.filteredTasks.next(filteredTasks.slice(0, amountToShow));
              // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
            });
          this.searchQueryChanged.next(this.searchQuery);
          this.amountToShowChanged.next(this.amountToShow);

          this.zone.runOutsideAngular(() => {
            document
              .querySelector('.tasks-card mat-list')
              .addEventListener('scroll', async () => {
                let scrollableElement = document.querySelector(
                  '.tasks-card mat-list'
                );
                if (
                  scrollableElement.clientHeight +
                    Math.ceil(scrollableElement.scrollTop) >=
                  scrollableElement.scrollHeight
                ) {
                  // you're at the bottom of the page
                  this.zone.run(() => {
                    this.loadMore();
                  });
                }
              });
          });
        });
      }
    });
  }

  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(task, searchQuery) {
    //console.log('task', task);
    let passesSearchFilter = true;
    if (searchQuery) {
      searchQuery.toLowerCase();
      passesSearchFilter = false;
      if (task.name) {
        if (task.name.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
      if (task.tags) {
        task.tags.forEach((tag) => {
          if (tag.name.toLowerCase().includes(searchQuery)) {
            passesSearchFilter = true;
          }
        });
      }
      if (task.phone) {
        if (task.phone.toLowerCase().includes(searchQuery)) {
          passesSearchFilter = true;
        }
      }
    }

    if (passesSearchFilter) {
      // console.log('user passes filter:', user);
      return task;
    }
  }

  loadMore() {
    this.amountToShow = this.amountToShow + this.amountToShowIncrease;
    this.amountToShowChanged.next(this.amountToShow);
  }

  selectTaskDetail(task, orgRefId) {
    console.log('item selected', task);
    // this.router.navigate(['/task-detail', this.user.organisationRef.id, task.id], {});
    this.router.navigate([`/task-detail/${orgRefId}/${task.id}`]);
  }

  createTask(orgRefId) {
    console.log('creat task button clicked');
    this.router.navigate([`/create-task/${orgRefId}`]);
  }

  editTask(task, orgRefId) {
    console.log('edit task button clicked');
    console.log('editTask', task, orgRefId);
    this.router.navigate([`/edit-task/${orgRefId}/${task.id}`]);
  }

  deleteTask(task, orgRefId) {
    console.log('delete task', task);
    console.log('orgRefId', orgRefId);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Taak verwijderen',
        text: `Weet je zeker dat je de taak ${task.name} wil verwijderen?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      const promises = [];
      if (result) {
        promises.push(
          await this.afs
            .doc<Task>(`organisations/${orgRefId}/tasks/${task.id}`)
            .delete()
        );

        this.afs
          .collection<Tag>(`organisations/${orgRefId}/tasks/${task.id}/tags`)
          .get()
          .subscribe((collection) => {
            collection.docs.forEach(async (doc) => {
              promises.push(
                await this.afs
                  .doc(
                    `organisations/${orgRefId}/tasks/${task.id}/tags/${doc.id}`
                  )
                  .delete()
              );
            });
          });

        this.afs
          .collection<Tag>(
            `organisations/${orgRefId}/tasks/${task.id}/volunteers`
          )
          .get()
          .subscribe((collection) => {
            collection.docs.forEach(async (doc) => {
              promises.push(
                await this.afs
                  .doc(
                    `organisations/${orgRefId}/tasks/${task.id}/volunteers/${doc.id}`
                  )
                  .delete()
              );
            });
          });

        await Promise.all(promises);
        this._snackBar.open('Taak verwijderd', '', {
          duration: 5000,
        });
      }
    });
  }

  filterId(item, compareItems, invert?) {
    const found = compareItems.find((lItem) => {
      return lItem.id === item.id;
    });
    if (invert) {
      if (!found) {
        return item;
      }
    } else {
      if (found) {
        return item;
      }
    }
  }
  async exportTableToExcel() {
    // const volunteersRef = this.afs.collection<Volunteer>('organisations/' + userData.organisationRef.id + '/volunteers');
    const exportArray = [];
    // const volunteersObservable = await this.volunteersCollection.get();
    this.filteredTasks.forEach((tasks) => {
      // console.log('vouchers', vouchers);
      tasks.forEach((taskDoc) => {
        // const volunteer = volunteerDoc.data() as Volunteer;
        const exportObj = {};
        let tagString = '';
        exportObj['Taaknaam'] = taskDoc.name ? taskDoc.name : '';
        exportObj['Berichttype'] = taskDoc.messageType
          ? taskDoc.messageType
          : '';
        exportObj['Bericht'] = taskDoc.message ? taskDoc.message : '';
        exportObj['Bericht om deelname te bevestigen'] =
          taskDoc.acceptedResponse ? taskDoc.acceptedResponse : '';
        exportObj['Bericht wanneer er nee wordt gezegd'] = taskDoc.noResponse
          ? taskDoc.noResponse
          : '';
        exportObj['Gewenst aantal deelnemers'] = taskDoc.requiredVolunteers
          ? taskDoc.requiredVolunteers
          : '';
        exportObj['Zelf deelnemers bevestigen'] = taskDoc.manualConfirm
          ? taskDoc.manualConfirm
          : '';
        exportObj['Bericht om deelname af te wijzen'] = taskDoc.rejectedResponse
          ? taskDoc.rejectedResponse
          : '';
        exportObj['Wachtbericht'] = taskDoc.yesResponse
          ? taskDoc.yesResponse
          : '';
        exportObj['Tijd & datum'] = taskDoc.date ? taskDoc.yesResponse : '';
        taskDoc.tags.forEach((tag) => {
          if (tagString === '') {
            tagString = tag.name;
          } else {
            tagString = tagString + `, ${tag.name}`;
          }
        });
        exportObj['Tags'] = tagString ? tagString : '';
        console.log('exportObj', exportObj);
        exportArray.push(exportObj);
      });
    });
    if (exportArray.length > 0) {
      console.log('vouchers', exportArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Volunteers');

      // /* save to file */
      XLSX.writeFile(wb, 'taken.xlsx');
    } else {
      // Nothing to export
    }
  }
}
