import { Component, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import {
  combineLatest,
  debounceTime,
  distinctUntilChanged,
  Observable,
  ReplaySubject,
  Subject,
} from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ModeratePostsComponent } from './dialogs/moderate-posts/moderate-post.component';
import { getAuth } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { User } from '../tasks/tasks.component';

export interface Post {
  id?: string;
  accepeted: boolean;
  date: string;
  message: string;
  moderated: boolean;
  resolved: boolean;
  title: string;
  type: string;
  userId: string;
  userName: string;
}

@Component({
  selector: 'app-post-container',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.scss'],
})
export class PostsComponent implements OnInit {
  zeroPostsFound: boolean;
  userName: String;
  loading = true;
  organisationId: string;
  filterForm: FormGroup;
  filterFormChangedDate: Subject<Date> = new Subject<Date>();
  textarea: String;
  postsCollection: AngularFirestoreCollection<Post>;
  totalPosts: number;
  posts: Observable<Post[]>;
  totalFilteredPosts: number;
  public filteredPosts: ReplaySubject<Post[]> = new ReplaySubject<Post[]>(1);

  constructor(
    private dialog: MatDialog,
    private db: AngularFirestore,
    private fb: FormBuilder
  ) {}

  async ngOnInit() {
    this.filterForm = this.fb.group({
      search: new FormControl(''),
    });

    const userId = getAuth().currentUser.uid;
    const userDoc = doc(this.db.firestore, `users/${userId}`);
    const user = (await getDoc(userDoc)).data() as User;
    this.organisationId = user.organisationRef.id;

    this.postsCollection = this.db.collection<Post>(
      `organisations/${this.organisationId}/bulletinBoard`,
      (ref) => ref.where('moderated', '==', false).orderBy('dateFrom', 'asc')
    );
    this.posts = this.postsCollection.valueChanges({ idField: 'id' });
    this.filterForm.valueChanges.subscribe((form) => {
      this.filterFormChangedDate.next(new Date());
    });

    const combinedObservable = combineLatest([
      this.posts,
      this.filterFormChangedDate.pipe(debounceTime(300)),
    ]);
    combinedObservable.pipe(distinctUntilChanged()).subscribe((values) => {
      console.log('values', values);
      const posts = values[0];
      const filters = this.filterForm.value;
      this.totalPosts = posts.length;

      if (posts.length == 0) {
        this.zeroPostsFound = true;
        console.log('ZeroPostFound', this.zeroPostsFound);
      }

      console.log('posts', posts.length);
      console.log('filters', filters);
      console.log('totalPosts', this.totalPosts);

      const filteredPosts = [];
      const searchValue = filters.search.toLowerCase();
      posts.forEach((post) => {
        if (post.title.toLowerCase().includes(searchValue)) {
          filteredPosts.push(post);
        }
      });

      this.filteredPosts.next(filteredPosts);

      this.loading = false;
    });
    this.filterFormChangedDate.next(new Date());
  }

  selectPostDetail(postData) {
    this.dialog.open(ModeratePostsComponent, {
      width: '800px',
      data: {
        userName: postData.userName,
        userId: postData.id,
        type: postData.type,
        title: postData.title,
        resolved: postData.resolved,
        moderated: postData.moderated,
        message: postData.message,
        dateTo: postData.dateTo,
        dateFrom: postData.dateFrom,
        accepted: postData.accepted,
      },
    });
  }
}
