import {
  Component,
  OnInit,
  ViewEncapsulation,
  Pipe,
  Inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import {
  Observable,
  combineLatest,
  pipe,
  of,
  ReplaySubject,
  Subject,
} from 'rxjs';
import {
  map,
  filter,
  take,
  debounceTime,
  distinctUntilChanged,
  startWith,
  switchMap,
} from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Title } from '@angular/platform-browser';
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { FormControl } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { ScheduleTaskComponent } from '../edit-task/dialogs/schedule-task/schedule-task.component';
// import { auth } from 'firebase/app';

export interface Task {
  name: string;
  requiredVolunteers: number;
  approvedVolunteers: number;
  message: string;
  address: string;
  postal: string;
  tags: [];
  date: Timestamp;
  isConcept: boolean;
  volenteers: [];
  acceptedResponse: string;
  rejectedResponse: string;
  manualConfirm: boolean;
  messageType: string;
  noResponse: string;
  yesResponse: string;
  sendType: string;
  sendStart: Timestamp;
  sendStartValue: number;
  sendStartType: string;
  sendEnd: Timestamp;
  sendEndValue: number;
  sendEndType: string;
}
export interface TaskId extends Task {
  id: string;
}
export interface Volunteer {
  name: string;
  status: string;
  statusTooltip: string;
  // ref: DocumentReference;
  responseText: string;
  responseStatus?: string;
  selectStatus: string;
  phone: string;
}

export interface VolunteerId extends Volunteer {
  id: string;
}

export interface Tag {
  name: string;
  color: string;
}
export interface TagId extends Tag {
  id: string;
}
export interface OrgVolunteer {
  name: string;
  fullName: string;
  status: string;
  statusTooltip: string;
  // ref: DocumentReference;
  responseStatus: string;
  responseText: string;
  selectStatus: string;
  tags: any;
  phone: string;
  hasGivenPermission: boolean;
}
export interface OrgVolunteerId extends OrgVolunteer {
  id: string;
}
export interface Tag {
  name: string;
  color: string;
}
export interface TagId extends Tag {
  id: string;
}
@Component({
  selector: 'app-task-details',
  templateUrl: './task-details.component.html',
  styleUrls: ['./task-details.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class TaskDetailsComponent implements OnInit {
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  private taskDoc: AngularFirestoreDocument<Task>;
  $item: Observable<Task>;
  item: Task;
  $volunteers: Observable<VolunteerId[]>;
  volunteers: VolunteerId[];
  $orgVolunteers: Observable<OrgVolunteerId[]>;
  orgVolunteers: OrgVolunteerId[];
  // linkedItems: Observable<TaskId[]>;
  fullItem: Observable<any>;
  orgId: string;
  taskId: string;
  private tagsCollection: AngularFirestoreCollection<Tag>;
  tags: Observable<TagId[]>;
  // itemTags: Observable<TagId[]>;
  mailToLink: string;
  fbLink: string;
  bobLink: string;
  twitterLink: string;
  currentDate = new Date();

  constructor(
    private db: AngularFirestore,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    public afAuth: AngularFireAuth,
    private titleService: Title,
    public dialog: MatDialog,
    private router: Router
  ) {}
  checkFilters(tag) {
    console.log(tag);
    return tag;
  }
  ngOnInit() {
    this.route.params.subscribe((params) => {
      this.orgId = params.orgId;
      this.taskId = params.id;
      this.taskDoc = this.db.doc<Task>(
        'organisations/' + this.orgId + '/tasks/' + this.taskId
      );
      // this.getMovie(id);
      this.getItem();
      this.getVolunteers();
      this.$orgVolunteers = this.db
        .collection('organisations')
        .doc(this.orgId)
        .collection('volunteers')
        .snapshotChanges()
        .pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as OrgVolunteer;
              const id = a.payload.doc.id;
              return { id, ...data };
            })
          )
        );
      this.$orgVolunteers.subscribe((orgVolunteers) => {
        console.log('orgVolunteers', orgVolunteers);
        this.orgVolunteers = orgVolunteers;
      });
    });
    this.route.queryParams.subscribe((params) => {
      // Defaults to 0 if no query param provided.
      console.log('params', params);
      if (params.invitePrompt) {
        this.inviteVolunteers();
      }
    });
  }

  updateVolunteerStatus(volunteer, status) {
    ///TODO
    if (this.item.messageType !== 'yesnoResponse') {
      return;
    }
    let dialogType;
    if (status === 'approved') {
      dialogType = ApproveDialogComponent;
    }
    const dialogRef = this.dialog.open(dialogType, {
      width: '300px',
      data: { name: volunteer.name },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed');
      if (result === 'confirmed') {
        if (volunteer.responseStatus === 'no') {
          volunteer.responseStatus = 'yes';
          volunteer.status = 'done';
          volunteer.statusTooltip = 'Bevestigd.';
        }
        await this.taskDoc
          .collection('volunteers')
          .doc(volunteer.id)
          .update({ selectStatus: status });
        await this.db.firestore.runTransaction((transaction) =>
          transaction.get(this.taskDoc.ref).then((itemDoc) => {
            let approvedVolunteers = itemDoc.data().approvedVolunteers;
            if (!approvedVolunteers) {
              approvedVolunteers = 0;
            }
            const newApprovedVolunteers = approvedVolunteers + 1;
            transaction.update(this.taskDoc.ref, {
              approvedVolunteers: newApprovedVolunteers,
            });
          })
        );
        console.log('volunteer updated');
      }
      if (result === 'rejected') {
        await this.taskDoc
          .collection('volunteers')
          .doc(volunteer.id)
          .update({ selectStatus: 'rejected' });
        console.log('volunteer rejected');
        if (volunteer.selectStatus === 'approved') {
          await this.db.firestore.runTransaction((transaction) =>
            transaction.get(this.taskDoc.ref).then((itemDoc) => {
              let approvedVolunteers = itemDoc.data().approvedVolunteers;
              if (!approvedVolunteers) {
                approvedVolunteers = 0;
              }
              const newApprovedVolunteers = approvedVolunteers - 1;
              transaction.update(this.taskDoc.ref, {
                approvedVolunteers: newApprovedVolunteers,
              });
            })
          );
        }
      }
    });
  }
  // showNewTaskDialog() {
  //   const dialogRef = this.dialog.open(NewTaskDialogComponent, {
  //     width: '600px',

  //   });

  //   dialogRef.afterClosed().subscribe(async result => {
  //     console.log('The dialog was closed with result:', result);
  //     if (result === 'directInvite') {
  //       this.inviteVolunteers();
  //     }
  //     if (result === 'search') {
  //       this.searchVolunteers();
  //     }
  //   });
  // }
  inviteVolunteers() {
    const dialogRef = this.dialog.open(InviteDialogComponent, {
      width: '600px',
      data: {
        orgId: this.orgId,
        itemId: this.taskId,
        tags: this.tags,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed with result:', result);
      if (result) {
        const batch = this.db.firestore.batch();
        result.forEach((volunteer) => {
          batch.set(
            this.taskDoc.collection('volunteers').doc(volunteer.id).ref,
            {
              name: volunteer.name,
              phone: volunteer.phone,
              userRef: this.db.collection('users').doc(volunteer.id).ref,
            },
            { merge: true }
          );
        });
        await batch.commit();
        console.log('volunteers added');
      }
    });
  }

  async scheduleTask() {
    const item = { ...this.item } as any;
    item.date = item.date.toDate();
    const scheduleDialogRef = this.dialog.open(ScheduleTaskComponent, {
      // maxWidth: '100vw',
      // maxHeight: '100vh',
      // height: '100%',
      // width: '100%',
      disableClose: true,
      data: { task: item },
    });
    scheduleDialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed with result:', result);
      if (result) {
        const saveObj = {} as Task;
        if (item.isConcept === true) {
          saveObj.isConcept = false;
        }

        saveObj.sendType = 'automatic';
        saveObj.sendStart = result.sendStartDate;
        saveObj.sendEnd = result.sendEndDate;
        saveObj.sendEndValue = result.sendEndValue;
        saveObj.sendEndType = result.sendEndType;
        console.log('sendStart', saveObj.sendStart);
        console.log('sendEnd', saveObj.sendEnd);
        //call saveItem if result is not null
        this.taskDoc.update(saveObj);
      }
    });
    // search volunteers now does the same as InviteVolunteers with everyone selected
    // must later be replaced with the user schedule based invites, no time to build bc deadlines
    // console.log('this.orgVolunteers', this.orgVolunteers);
    // const batch = this.db.firestore.batch();
    // this.orgVolunteers.forEach((volunteer) => {
    //   console.log('volunteer', volunteer);
    //   if (volunteer.hasGivenPermission) {
    //     batch.set(
    //       this.taskDoc.collection('volunteers').doc(volunteer.id).ref,
    //       {
    //         name: volunteer.name,
    //         phone: volunteer.phone,
    //         verifyTags: true,
    //         userRef: this.db.collection('users').doc(volunteer.id).ref,
    //       },
    //       { merge: true }
    //     );
    //   }
    // });
    // await batch.commit();
    // console.log('volunteers added');
  }

  getVolunteers() {
    // this.volunteers = this.itemDoc.collection('volunteers').valueChanges();
    this.$volunteers = this.taskDoc
      .collection('volunteers')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions
            .map((a) => {
              const data = a.payload.doc.data() as Volunteer;
              if (
                data.responseStatus === 'yes' &&
                data.selectStatus === 'approved'
              ) {
                data.status = 'done';
                data.statusTooltip = 'Bevestigd.';
              } else if (data.responseStatus === 'no') {
                data.status = 'declined';
                data.statusTooltip = 'Heeft nee gezegd.';
              } else if (data.selectStatus === 'rejected') {
                data.status = 'declined';
                data.statusTooltip = 'Is afgewezen.';
              } else {
                data.status = 'inprogress';
                if (!data.responseStatus) {
                  if (data.responseText) {
                    data.statusTooltip = 'Heeft gereageerd.';
                    data.status = 'done';
                  } else {
                    data.statusTooltip = 'Heeft nog niet gereageerd.';
                  }
                } else if (!data.selectStatus) {
                  data.statusTooltip = 'Moet nog geaccepteerd worden.';
                }
              }
              const id = a.payload.doc.id;
              return { id, ...data };
            })
            .sort(function (a, b) {
              if (a.responseStatus === 'yes' && b.responseStatus !== 'yes') {
                return -1;
              } else if (a.responseStatus && !b.responseStatus) {
                return -1;
              } else if (
                a.responseStatus === b.responseStatus &&
                a.name.toLowerCase() < b.name.toLowerCase()
              ) {
                return -1;
              }
              return 0;
            })
        )
      );

    this.$volunteers.subscribe((volunteers) => {
      console.log('volunteers', volunteers);
      this.volunteers = volunteers;
    });
  }
  getAutomaticTimeStrings(value, type) {
    let timeString = value.toString() + ' ';
    if (value === 1) {
      if (type === 'hours') {
        timeString = timeString + 'uur';
      }
      if (type === 'days') {
        timeString = timeString + 'dag';
      }
      if (type === 'weeks') {
        timeString = timeString + 'week';
      }
    } else {
      if (type === 'hours') {
        timeString = timeString + 'uren';
      }
      if (type === 'days') {
        timeString = timeString + 'dagen';
      }
      if (type === 'weeks') {
        timeString = timeString + 'weken';
      }
    }
    return timeString;
  }
  getItem() {
    console.log('organisations/' + this.orgId + '/tasks/' + this.taskId);
    this.$item = this.taskDoc.valueChanges().pipe((item) => {
      return item;
    });
    this.$item.subscribe((item) => {
      console.log('item', item);
      this.item = item;
    });
    this.tagsCollection = this.db.collection<TagId>(
      'organisations/' + this.orgId + '/tasks/' + this.taskId + '/tags',
      (ref) => ref.orderBy('index', 'asc')
    );
    this.tags = this.tagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.tags.subscribe((tag) => {
      console.log('tags', tag);
    });
  }
}

// @Component({
//   selector: 'app-new-task-dialog',
//   templateUrl: 'dialogs/new-task.html',
// })

// export class NewTaskDialogComponent {
//   constructor(
//     public dialogref: MatDialogRef<NewTaskDialogComponent>,
//     @Inject(MAT_DIALOG_DATA) public data: any) { }
// }

@Component({
  selector: 'app-save-dialog',
  templateUrl: 'dialogs/save-dialog.html',
})
export class SaveDialogComponent {
  constructor(
    public dialogref: MatDialogRef<SaveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}
@Component({
  selector: 'app-approve-dialog',
  templateUrl: 'dialogs/approve-dialog.html',
})
export class ApproveDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ApproveDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}
}

@Component({
  selector: 'app-invite-dialog',
  templateUrl: 'dialogs/invite-dialog.html',
})
export class InviteDialogComponent implements OnInit {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  @ViewChild('tagInput', { static: false })
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  $orgVolunteers: Observable<OrgVolunteerId[]>;
  orgVolunteers: OrgVolunteerId[];
  $orgVolunteersWithTags: Observable<OrgVolunteerId[]>;
  $taskVolunteers: Observable<OrgVolunteerId[]>;
  selectedVolunteers: [];
  allSelected: boolean;
  tags: ReplaySubject<TagId[]> = new ReplaySubject<TagId[]>(1);
  filteredTags: ReplaySubject<TagId[]> = new ReplaySubject<TagId[]>(1);
  private allTagsCollection: AngularFirestoreCollection<Tag>;
  $allTags: Observable<TagId[]>;
  formCtrl = new FormControl();
  selectedTags = [];
  taskTags = [];
  public filteredVolunteers: ReplaySubject<OrgVolunteerId[]> =
    new ReplaySubject<OrgVolunteerId[]>(1);

  constructor(
    public dialogRef: MatDialogRef<InviteDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private db: AngularFirestore
  ) {
    // this.filteredTags = this.formCtrl.valueChanges.pipe(
    //   startWith(null),
    //   map((tag: string | null) => tag ? this._filter(tag) : this.allTags.slice()));
  }
  ngOnInit() {
    this.allSelected = false;
    console.log('this.data', this.data);
    this.data.tags.subscribe((value) => {
      value.forEach((val) => {
        const tag = {
          name: val.name,
          color: val.color,
          id: val.id,
        };
        console.log('selectedTag', tag);
        this.taskTags.push(tag);
        this.selectedTags.push(tag);
      });
      console.log('this.tags', value);
    });
    this.tags.next(this.selectedTags);
    this.allTagsCollection = this.db.collection<TagId>(
      'organisations/' + this.data.orgId + '/tags/'
    );
    this.$allTags = this.allTagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );
    this.tags.subscribe((tag) => {
      console.log('tags', tag);
    });
    this.$orgVolunteers = this.db
      .collection('organisations')
      .doc(this.data.orgId)
      .collection('volunteers')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as OrgVolunteer;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
    this.$orgVolunteers.subscribe((orgVolunteers) => {
      console.log('orgVolunteers', orgVolunteers);
    });
    this.$orgVolunteersWithTags = this.$orgVolunteers.pipe(
      switchMap(async (val) => {
        val.map(async (user) => {
          user.tags = await this.db
            .collection('organisations')
            .doc(this.data.orgId)
            .collection('volunteers')
            .doc(user.id)
            .collection('tags')
            .ref.get()
            .then((col) => {
              const tags = [];
              col.forEach((doc) => {
                let tag = doc.data();
                tag.id = doc.id;
                tags.push(tag);
              });
              return tags;
            });
        });

        return val;
      })
    );
    this.$orgVolunteersWithTags.subscribe((val) => {
      console.log('orgVolunteersWithTags', val);
    });
    this.$taskVolunteers = this.db
      .collection('organisations')
      .doc(this.data.orgId)
      .collection('tasks')
      .doc(this.data.itemId)
      .collection('volunteers')
      .snapshotChanges()
      .pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as OrgVolunteer;

            const id = a.payload.doc.id;
            return { id, ...data };
          })
        )
      );
    this.$taskVolunteers.subscribe((volunteers) => {
      console.log('taskVolunteers', volunteers);
    });
    const combinedObservable = combineLatest(
      this.tags,
      this.$orgVolunteersWithTags,
      this.$taskVolunteers,
      this.$allTags
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const tags = values[0];
        const volunteers = values[1];
        const taskVolunteers = values[2];
        const allTags = values[3];
        console.log('this.taskTags', this.taskTags);
        console.log('tags', tags);
        console.log('taskVolunteers', taskVolunteers);
        const filteredVolunteers = volunteers.filter((volunteer) => {
          let hasAllTags = true;
          console.log('volunteer', volunteer);
          // check if volunteer already had interaction with task;
          if (!volunteer.hasGivenPermission) {
            return; //dont show people that didnt give permission yet
          }
          const taskVolunteerFound = taskVolunteers.find((volun) => {
            console.log('volun', volun);
            return volun.id === volunteer.id;
          });
          console.log('taskVolunteerFound', taskVolunteerFound);
          if (taskVolunteerFound) {
            return;
          }

          // check for tags
          tags.forEach((tag) => {
            let hasThisTag = false;
            if (volunteer.tags) {
              volunteer.tags.forEach((volunteerTag) => {
                if (volunteerTag.id === tag.id) {
                  hasThisTag = true;
                }
              });
              if (!hasThisTag) {
                hasAllTags = false;
              }
            } else {
              hasAllTags = false;
            }
          });
          if (hasAllTags) {
            return volunteer;
          }
        });
        console.log('filteredVolunteers', filteredVolunteers);
        this.filteredVolunteers.next(filteredVolunteers);
      });

    const combinedFilter = combineLatest(
      this.formCtrl.valueChanges,
      this.$allTags
    );
    combinedFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('filteredTags', filteredTags);
        this.filteredTags.next(filteredTags);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });

    console.log('orgId:', this.data.orgId);
  }

  checkTags(tag) {
    return tag === true;
  }

  selectAll() {
    if (!this.allSelected) {
      this.allSelected = true;
    } else {
      this.allSelected = false;
    }
  }

  // add(event: MatChipInputEvent): void {
  //   const input = event.input;
  //   const value = event.value;
  //   console.log('event', event);
  //   console.log('input', input);
  //   console.log('value', value);
  //   if (value) {
  //     // this.selectedTags.push(value.trim());
  //     this.tags.next(value);
  //   }

  //   // Reset the input value
  //   if (input) {
  //     input.value = '';
  //   }

  //   this.fruitCtrl.setValue(null);
  // }
  selected(event: MatAutocompleteSelectedEvent): void {
    // this.selectedTags.push(event.option.viewValue);
    const value = event.option.value;
    console.log('value', value);
    this.selectedTags.push(value);
    this.tags.next(this.selectedTags);
    this.tagInput.nativeElement.value = '';
    this.formCtrl.setValue(null);
  }

  remove(tag): void {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      this.tags.next(this.selectedTags);
    }
  }
}
