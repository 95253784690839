import {
  Component,
  OnInit,
  NgZone,
  ViewChild,
  ElementRef,
} from '@angular/core';
import { COMMA, ENTER, SPACE } from '@angular/cdk/keycodes';
import {
  FormBuilder,
  FormGroup,
  Validators,
  FormControl,
} from '@angular/forms';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  AngularFirestore,
  AngularFirestoreDocument,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { Timestamp } from '@firebase/firestore-types';
import {
  Observable,
  ReplaySubject,
  Subject,
  combineLatest,
  Subscription,
} from 'rxjs';

import {
  map,
  take,
  finalize,
  filter,
  takeUntil,
  debounceTime,
  delay,
  startWith,
  distinctUntilChanged,
} from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import {
  MatAutocompleteSelectedEvent,
  MatAutocomplete,
} from '@angular/material/autocomplete';
import { ScheduleTaskComponent } from './dialogs/schedule-task/schedule-task.component';
import { getFormValidationErrors } from 'src/app/helpers';
import { DocumentReference, doc, getDoc } from 'firebase/firestore';
export interface Task {
  name: string;
  message: string;
  answer: string;
  date: any;
  isConcept: boolean;
  time: string;
  postal: string;
  street: string;
  city: string;
  houseNumber: number;
  houseNumberSuffix: string;
  messageType: boolean;
  image: string;
  maxAmountGuests: number;
  byAssociation: boolean;
  nameOrg: string;
  orgAcronym: string;
  membersOnly: boolean;
  sendType: string;
  sendStart: Timestamp;
  sendStartValue: number; //No longer used for new tasks
  sendStartType: string; //No longer used for new tasks
  sendEnd: Timestamp;
  requiredVolunteers: number;
  invitedVolunteers: number;
  sendEndValue: number;
  sendEndType: string;
  tags: string[];
  // email: string;
  // phone: string;
  // website: string;
  // id: string;
}
export interface Tag {
  name: string;
  color: string;
  index: number;
}

export interface TagId extends Tag {
  id: string;
}
export interface TaskId extends Task {
  id: string;
}

export enum MessageType {
  yesnoResponse = 'yesnoResponse',
  open = 'open',
}

@Component({
  selector: 'app-edit-task',
  templateUrl: './edit-task.component.html',
  styleUrls: ['./edit-task.component.scss'],
})
export class EditTaskComponent implements OnInit {
  tab: number = 1;
  hiddenForm: boolean = false;

  showHiddenForms = false;
  formChanged: boolean;
  coverImgFileUploaded: File;

  messageType = MessageType;
  saving = false;
  isHandset$: Observable<boolean> = this.breakpointObserver
    .observe(Breakpoints.Handset)
    .pipe(map((result) => result.matches));
  taskForm: FormGroup;
  taskDoc: DocumentReference;
  task: Task;
  copy: string;
  taskId: string;
  organisationId: string;
  private tagsCollection: AngularFirestoreCollection<Tag>;
  tags: ReplaySubject<TagId[]> = new ReplaySubject<TagId[]>(1);
  allTags: Observable<TagId[]>;
  newTask: boolean;
  messageNotSent = false;
  dbCollection: string;
  public linkTagsCtrl: FormControl = new FormControl();
  public OrgItemCtrl: FormControl = new FormControl();
  public tagFilterCtrl: FormControl = new FormControl();
  public filteredTagsCtrl: ReplaySubject<Tag[]> = new ReplaySubject<Tag[]>(1);
  private taskTagsCollection: AngularFirestoreCollection<Tag>;
  mentionItems: string[] = ['Naam', 'Datum', 'Dag', 'Tijd', 'Organisatie'];
  taskTags: Observable<TagId[]>;
  @ViewChild('tagInput', { static: false })
  tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  readonly separatorKeysCodes = [COMMA, ENTER, SPACE] as const;
  tagCtrl = new FormControl();
  selectedTags = [];
  filteredTags: ReplaySubject<TagId[]> = new ReplaySubject<TagId[]>(1);
  urlRegEXp = new RegExp(
    /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i
  );

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();
  constructor(
    private db: AngularFirestore,
    private breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private fb: FormBuilder,
    private _ngZone: NgZone,
    private snackBar: MatSnackBar,
    private _location: Location,
    private storage: AngularFireStorage,
    private router: Router,
    public dialog: MatDialog
  ) {
    this.taskId = this.route.snapshot.paramMap.get('id');
    this.organisationId = this.route.snapshot.paramMap.get('orgId');
    this.copy = this.route.snapshot.queryParamMap.get('copy');

    console.log(this.route.routeConfig.path);

    this.dbCollection = `organisations/${this.organisationId}/tasks/`;
    if (this.taskId) {
      this.taskDoc = doc(
        this.db.firestore,
        `organisations/${this.organisationId}/tasks/${this.taskId}`
      );
      this.taskTagsCollection = this.db.collection<Tag>(
        this.dbCollection + this.taskId + '/tags'
      );

      this.taskTags = this.taskTagsCollection.snapshotChanges().pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Tag;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        ),
        take(1)
      );
      this.taskTags.subscribe((value) => {
        value.forEach((tag) => {
          this.selectedTags.push(tag);
        });
        console.log('this.selectedTags', this.selectedTags);
      });
      if (this.copy === 'true') {
        this.taskId = this.db.createId();
        this.newTask = true;
        this.messageNotSent = true;
      }
    } else {
      this.taskId = this.db.createId();
      this.newTask = true;
      this.messageNotSent = true;
      console.log('messageNotSent', this.messageNotSent);
      this.taskTagsCollection = this.db.collection<Tag>(
        this.dbCollection + this.taskId + '/tags'
      );
      this.taskTags = this.taskTagsCollection.snapshotChanges().pipe(
        map((actions) =>
          actions.map((a) => {
            const data = a.payload.doc.data() as Tag;
            const id = a.payload.doc.id;
            return { id, ...data };
          })
        ),
        take(1)
      );
    }
    console.log('this.taskId', this.taskId);
    console.log('this.organisationId', this.organisationId);
  }

  @ViewChild('autosize', { static: false }) autosize: CdkTextareaAutosize;

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this._ngZone.onStable
      .pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }
  openFileInput(htmlId) {
    const element: HTMLElement = document.getElementById(htmlId) as HTMLElement;
    element.click();
  }
  uploadedFile(event, type) {
    console.log(event);
    this.formChanged = true;
    const file = event.target.files[0] as File;
    if (type === 'coverImg') {
      if (file.size > 2048000) {
        this.snackBar.open('Dit bestand moet onder de 2 MB zijn.', 'X', {
          duration: 5000,
        });
        return;
      } else {
        this.coverImgFileUploaded = file;
      }
    }
  }

  async ngOnInit() {
    this.tagsCollection = this.db.collection<Tag>(
      `organisations/${this.organisationId}/tags`,
      (ref) => ref.orderBy('name')
    );
    this.allTags = this.tagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      ),
      take(1)
    );

    this.taskForm = this.fb.group({
      name: ['', Validators.required],
      message: ['', Validators.required],
      // answer: ['', Validators.required], Disabled seems it was never entered and i don't know what it's for
      description: ['', Validators.required],
      date: ['', Validators.required],
      time: ['', Validators.required],
      cost: [],
      postal: ['', Validators.required],
      street: ['', Validators.required],
      city: ['', Validators.required],
      houseNumber: ['', Validators.required],
      houseNumberSuffix: [''],
      requiredVolunteers: [],
      messageType: [MessageType.yesnoResponse, Validators.required],
      image: [''],
      byAssociation: [''],
      nameOrg: [''],
      orgAcronym: [''],
      membersOnly: [''],
      yesResponse: [''],
      noResponse: ['Geen probleem. Ik wens je een prettige dag verder!'],
      manualConfirm: [],
      acceptedResponse: ['', Validators.required],
      rejectedResponse: [
        'Bedankt voor je reactie op dit bericht, er waren te veel aanmeldingen daarom kunnen we je aanmelding helaas niet accepteren.',
      ],
      redirectUrl: [, Validators.pattern(this.urlRegEXp)],
      // sendType: ['automatic'],
      // sendStartValue: [1, Validators.required],
      // sendStartType: ['weeks', Validators.required],
      // sendEndValue: [1, Validators.required],
      // sendEndType: ['hours', Validators.required],
      // email: [''],
      // phone: [''],
      // website: [''],
    });
    this.taskForm.get('messageType').valueChanges.subscribe((value) => {
      if (value === MessageType.open) {
        this.taskForm.removeControl('message');
        this.taskForm.removeControl('date');
        this.taskForm.removeControl('time');
        this.taskForm.removeControl('cost');
        this.taskForm.removeControl('postal');
        this.taskForm.removeControl('street');
        this.taskForm.removeControl('city');
        this.taskForm.removeControl('houseNumber');
      } else {
        this.taskForm.addControl(
          'message',
          new FormControl('', Validators.required)
        );
        this.taskForm.addControl(
          'date',
          new FormControl('', Validators.required)
        );
        this.taskForm.addControl(
          'time',
          new FormControl('', Validators.required)
        );
        this.taskForm.addControl(
          'cost',
          new FormControl('', Validators.required)
        );
        this.taskForm.addControl(
          'postal',
          new FormControl('', Validators.required)
        );
        this.taskForm.addControl(
          'street',
          new FormControl('', Validators.required)
        );
        this.taskForm.addControl(
          'city',
          new FormControl('', Validators.required)
        );
        this.taskForm.addControl(
          'houseNumber',
          new FormControl('', Validators.required)
        );
      }
    });

    this.taskForm.get('yesResponse').disable();
    if (this.taskDoc) {
      this.task = (await getDoc(this.taskDoc)).data() as Task;
    }
    console.log('task', this.task);
    if (this.task) {
      console.log('item val', this.task);
      // tslint:disable-next-line: prefer-const
      let formVal: any = this.task;

      if (formVal.date) {
        formVal.date = this.task.date.toDate();
      }
      if (formVal.sendType === 'later') {
        this.messageNotSent = true;
      }
      console.log('formval', formVal);
      this.taskForm.patchValue(formVal);
    }
    this.taskForm.get('manualConfirm').valueChanges.subscribe((val) => {
      if (val) {
        this.taskForm.get('yesResponse').enable();
      } else {
        this.taskForm.get('yesResponse').disable();
      }
    });

    this.linkTagsCtrl.valueChanges.subscribe((val) => {
      console.log('linkTagsCtrl val', val);
    });
    const combinedOrgItemLinkObservable = combineLatest(
      this.filteredTagsCtrl,
      this.OrgItemCtrl.valueChanges
    );
    combinedOrgItemLinkObservable.subscribe((values) => {
      console.log('values', values);
      const items = values[0];
      const orgItem = values[1];

      let currentLinkItems = this.linkTagsCtrl.value || [];
      console.log('currentLinkItems', currentLinkItems);
      const orgLinkItem = items.filter((item) =>
        this.filterId(item, [orgItem])
      );
      console.log('orgLinkItem', orgLinkItem);
      currentLinkItems = currentLinkItems.concat(orgLinkItem);
      console.log('currentLinkItems', currentLinkItems);
      this.linkTagsCtrl.setValue(currentLinkItems);
    });
    this.OrgItemCtrl.valueChanges.subscribe((val) => {
      console.log('OrgItemCtrl val', val);

      this.taskForm.patchValue({
        streetName: val.streetName,
        cityName: val.cityName,
        postal: val.postal,
        houseNumber: val.houseNumber,
      });
    });
    const combinedFilter = combineLatest(
      this.tagCtrl.valueChanges,
      this.allTags
    );
    combinedFilter
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((values) => {
        console.log('values', values);
        const filteredTags = [];
        const input = values[0];
        const allTags = values[1];
        console.log('allTags', allTags);
        allTags.forEach((tag) => {
          const tagName = tag.name.toLowerCase();
          if (tagName.includes(input)) {
            filteredTags.push(tag);
          }
        });

        console.log('filteredTags', filteredTags);
        this.filteredTags.next(filteredTags);
        // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
      });
    this.tagCtrl.setValue('');
    // // listen for search field value changes
    // const combinedItemsObservable = combineLatest(this.filteredTagsCtrl, this.taskTags);
    // combinedItemsObservable.pipe(
    //   take(1),
    // ).subscribe(values => {
    //   const items: Array<any> = values[0];
    //   const linkedItems: Array<any> = values[1];
    //   console.log('combinedItemsObservable values', values);
    //   console.log('this.taskForm', this.taskForm);
    //   if (linkedItems.length > 0) {
    //     // find the items that match linkedItems in the items array, set results in linkTagsCtrl;
    //     const setItems = items.filter(item => this.filterId(item, linkedItems));
    //     console.log('setItems', setItems);
    //     this.linkTagsCtrl.setValue(setItems);
    //     // this.linkTagsCtrl.setValue([items[0], items[1]]);
    //   }
    // });
    // const combinedItemsFilterObversable = combineLatest(this.tags, this.tagFilterCtrl.valueChanges);
    // combinedItemsFilterObversable
    //   .pipe(
    //     takeUntil(this._onDestroy),
    //     debounceTime(300),
    //     map(combinedData => {
    //       const tags = combinedData[0];
    //       const search = combinedData[1];
    //       console.log('tags', tags);
    //       console.log('search', search);
    //       if (!combinedData[0]) {
    //         return [];
    //       }
    //       // simulate server fetching and filtering data
    //       return tags.filter(item => this.checkFilters(item, search));
    //     }),
    //     delay(500)
    //   )
    //   .subscribe(filteredItems => {
    //     console.log('filteredItems', filteredItems);
    //     this.filteredTagsCtrl.next(filteredItems);
    //   },
    //     error => {
    //       // no errors in our simulated example
    //       // handle error...
    //     });
    // this.tagFilterCtrl.setValue('');
  }

  remove(tag: string): void {
    const index = this.selectedTags.indexOf(tag);

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      this.tags.next(this.selectedTags);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    // this.selectedTags.push(event.option.viewValue);
    console.log('event', event);
    const value = event.option.value;
    console.log('value', value);
    this.selectedTags.push(value);
    this.tags.next(this.selectedTags);
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue('');
  }

  checkFilters(item: Tag, search: string) {
    if (item.name.toLowerCase().indexOf(search.toLowerCase()) !== -1) {
      return item;
    }
    // if (item.description.indexOf(search) !== -1) {
    //   return item;
    // }
  }
  cancel() {
    // console.log('itemForm', this.itemForm);
    // console.log('itemForm', this.itemForm.dirty);
    if (this.taskForm.dirty) {
      if (confirm('Weet je zeker dat je wil annuleren zonder op te slaan?')) {
        console.log('Implement delete functionality here');
        this._location.back();
      }
    } else {
      this._location.back();
    }
  }

  async save(type: 'concept' | 'manual' | 'scheduled') {
    if (type != 'concept') {
      if (this.taskForm.invalid) {
        this.taskForm.markAllAsTouched();
        return;
      }
    }

    this.saving = true;
    let submitItem = this.taskForm.value;
    console.log('form', submitItem);

    if (this.coverImgFileUploaded) {
      const filePath = `organisations/${this.organisationId}/images/${this.taskId}`;
      const upload = await this.storage.upload(
        filePath,
        this.coverImgFileUploaded
      );
      submitItem.image = await upload.ref.getDownloadURL();
      console.log('submitItem.image', submitItem.image);
    } else if (!this.task || !this.task.image) {
      submitItem.image = null;
    }

    if (submitItem.date && submitItem.time) {
      const hourString = submitItem.time.substring(0, 2);
      const minuteString = submitItem.time.substring(3, 5);
      console.log('hourString:', hourString);
      console.log('minuteString:', minuteString);
      const hour = Number(hourString);
      const minute = Number(minuteString);
      submitItem.date.setHours(hour);
      submitItem.date.setMinutes(minute);
    }

    if (
      submitItem.street &&
      submitItem.city &&
      submitItem.postal &&
      submitItem.houseNumber
    ) {
      submitItem.maps =
        `${submitItem.street}, ${submitItem.city}, ${submitItem.postal}, ${submitItem.houseNumber}` +
        submitItem.houseNumberSuffix
          ? `, ${submitItem.houseNumberSuffix}`
          : '';
    }
    if (type === 'scheduled') {
      const scheduleDialogRef = this.dialog.open(ScheduleTaskComponent, {
        // maxWidth: '100vw',
        // maxHeight: '100vh',
        // height: '100%',
        // width: '100%',
        disableClose: true,
        data: { task: this.taskForm.value },
      });
      scheduleDialogRef.afterClosed().subscribe((result) => {
        console.log('The dialog was closed with result:', result);
        if (result) {
          submitItem.sendType = 'automatic';
          submitItem.sendStart = result.sendStartDate;
          submitItem.sendEnd = result.sendEndDate;
          submitItem.sendEndValue = result.sendEndValue;
          submitItem.sendEndType = result.sendEndType;
          console.log('sendStart', submitItem.sendStart);
          console.log('sendEnd', submitItem.sendEnd);
          //call saveItem if result is not null
          this.saveItem(submitItem, type);
        }
      });
    }
    this.saveItem(submitItem, type);
  }
  async saveItem(submitItem: Task, type: 'concept' | 'manual' | 'scheduled') {
    console.log('submitItem', submitItem);

    const batch = this.db.firestore.batch();
    // this.linkTagsCtrl.value.forEach((tag) => {
    //   this.db
    //     .doc(
    //       `organisations/${this.organisationId}/task/${this.taskId}/tags/${tag.id}`
    //     )
    //     .set(tag);
    // });
    const selectedTags = this.selectedTags;
    console.log('selectedTags', selectedTags);
    await this.taskTags.forEach(async (tags) => {
      tags.forEach(async (tag) => {
        console.log('taskTags tag', tag);
        const foundTag = await selectedTags.find((lfTag) => {
          if (lfTag.id === tag.id) {
            return lfTag;
          }
        });
        if (!foundTag) {
          batch.delete(
            this.db
              .collection(this.dbCollection + this.taskId + '/tags')
              .doc(tag.id).ref
          );
        }
      });
    });
    submitItem.isConcept = type === 'concept' ? true : false;
    submitItem.tags = [];
    if (this.selectedTags) {
      this.selectedTags.forEach((tag) => {
        submitItem.tags.push(tag.id);
        tag.index = this.selectedTags.indexOf(tag) + 1;
        batch.set(
          this.db
            .collection(this.dbCollection + this.taskId + '/tags')
            .doc(tag.id).ref,
          tag,
          { merge: true }
        );
        this.db
          .doc(
            `organisations/${this.organisationId}/tasks/${this.taskId}/tags/${tag.id}`
          )
          .set(tag);
      });
    }

    batch.set(
      this.db.collection(this.dbCollection).doc(this.taskId).ref,
      submitItem,
      { merge: true }
    );

    await batch.commit();
    if (type === 'manual') {
      this.router.navigate(
        [`/task-detail/${this.organisationId}/${this.taskId}`],
        { queryParams: { invitePrompt: true } }
      );
    } else if (type === 'concept') {
      this.router.navigate([
        `/task-detail/${this.organisationId}/${this.taskId}`,
      ]);
    } else {
      this.router.navigate([
        `/task-detail/${this.organisationId}/${this.taskId}`,
      ]);
    }
  }
  removeImage() {
    console.log('remove');
    this.taskForm.patchValue({ img: '' });
    this.taskForm.markAsDirty();
  }

  openImageInNewWindow = () => {
    window.open(this.task.image);
  };

  urlify(name): string {
    console.log('name', name);
    if (name) {
      const regex = RegExp(' ', 'g');
      return name.replace(regex, '-');
    } else {
      return '';
    }
  }

  filterId(item, compareItems, invert?) {
    const found = compareItems.find((lItem) => {
      return lItem.id === item.id;
    });
    if (invert) {
      if (!found) {
        return item;
      }
    } else {
      if (found) {
        return item;
      }
    }
  }
  changeTab(number) {
    this.tab = number;
  }
  nextTab() {
    this.tab = this.tab + 1;
  }

  hiddenForms() {
    this.hiddenForm = !this.hiddenForm;
  }

  logFormsWithErrors = getFormValidationErrors;
}
