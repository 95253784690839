import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { Timestamp } from '@firebase/firestore-types';
import { AngularFireAuth } from '@angular/fire/compat/auth';
//import { Reference } from '@angular/fire/storage/interfaces';

export interface User {
  name?: string;
  // organisation: object;
  // organisation?: {
  //   ref: DocumentReference;
  //   role: string;
  // };
  organisationRef?: DocumentReference;
  email?: string;
  id?: string;
}
export interface UserId extends User {
  id: string;
}

// export interface Organisation {
//   name: string;
//   organisation: {
//     ref: Reference;
//     role: string;
//   };
//   email: string;
// }
// export interface OrganisationId extends User { id: string; }

@Injectable({
  providedIn: 'root',
})
export class ConstantsService {
  userDoc: AngularFirestoreDocument<User>;
  user: Observable<User>;
  // organisationDoc: AngularFirestoreDocument<Organisation>;
  // organisation: Observable<OrganisationId>;
  userId: string;
  userEmail: string;
  testUser: {};
  constructor(public afAuth: AngularFireAuth, public afs: AngularFirestore) {
    // this.user = new Observable();
    this.afAuth.user.subscribe((user) => {
      this.userId = user.uid;
      this.userEmail = user.email;
      console.log('this.userEmail', this.userEmail);
      this.userDoc = afs.doc<User>('users/' + user.uid);
      this.user = this.userDoc.valueChanges().pipe(
        map((userData) => {
          // console.log('userData =>', userData);
          if (!userData) {
            userData = {};
          }
          userData.email = user.email;
          userData.id = user.uid;
          if (userData.organisationRef) {
            userData.organisationRef = userData.organisationRef;
          }
          // console.log('userData =>', userData);
          return userData;
        })
      );
    });

    // console.log('currentUser =>', this.afAuth.auth.currentUser);
    // console.log('currentUser', this.afAuth.auth.currentUser);
    // this.user.subscribe( userDoc => {
    //   console.log('userDoc => ', userDoc);
    // });
  }
}
