import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { MatDialogRef } from '@angular/material/dialog';
import { combineLatest, debounceTime, Observable, ReplaySubject, Subject } from 'rxjs';
import { Organisation } from 'src/app/settings/settings.component';

@Component({
  selector: 'app-change-organisation',
  templateUrl: './change-organisation.component.html',
  styleUrls: ['./change-organisation.component.scss'],
})
export class ChangeOrganisationComponent implements OnInit {
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  allOrganisations: Observable<Organisation[]>;
  public firstOrgId: string;
  public filteredOrganisations: ReplaySubject<Organisation[]> = new ReplaySubject<Organisation[]>(1);

  constructor(
    private db: AngularFirestore,
    private dialogRef: MatDialogRef<ChangeOrganisationComponent>
  ) {}

  ngOnInit(): void {
    const orgsRef = this.db.collection<Organisation>('/organisations/', (ref) =>
      ref.orderBy('name')
    );
    this.allOrganisations = orgsRef.valueChanges({idField: 'id'});
    this.allOrganisations.subscribe((orgs) => {
      console.log('orgs', orgs);
    })
    const combinedObservable = combineLatest([
      this.allOrganisations,
      this.searchQueryChanged.pipe(debounceTime(300)),
    ]);
    combinedObservable.pipe().subscribe((values) => {
      console.log('combinedObservableValues:', values);
      const organisations = values[0];
      const searchQuery = values[1].toLowerCase();

      const filteredOrganisations = organisations.filter((organisation) => {
        if (organisation.name && organisation.name.toLowerCase().includes(searchQuery)) {
          return organisation;
        }
      });
      console.log('filteredOrganisations', filteredOrganisations);
      this.firstOrgId = filteredOrganisations.length > 0 ? filteredOrganisations[0].id : null;
      this.filteredOrganisations.next(filteredOrganisations);
    });
    this.searchQueryChanged.next('');
  }

  select(orgId: string) {
    this.dialogRef.close(orgId);
  } 
}
