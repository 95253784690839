import { Component, Inject, OnInit } from '@angular/core';
import {
  AngularFirestore,
  AngularFirestoreCollection,
} from '@angular/fire/compat/firestore';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { User } from 'src/app/tasks/tasks.component';

export interface DialogData {
  userName: String;
  userId: String;
  type: String;
  title: String;
  resolved: boolean;
  moderated: boolean;
  message: String;
  dateTo: any;
  dateFrom: any;
  accepted: boolean;
}

@Component({
  selector: 'app-posts',
  templateUrl: './moderate-post.component.html',
  styleUrls: ['./moderate-post.component.scss'],
})
export class ModeratePostsComponent implements OnInit {
  radioButtonValue: boolean;
  postsCollection: AngularFirestoreCollection;
  organisationId: String;
  messageValue: String;
  form: FormGroup;
  accepted: boolean = false;

  constructor(
    private db: AngularFirestore,
    private fb: FormBuilder,
    public dialogRef: MatDialogRef<ModeratePostsComponent>,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {}

  async ngOnInit() {
    this.form = this.fb.group({
      accepted: [this.accepted],
      reasonOfDenial: [null, Validators.required],
    });

    const userId = getAuth().currentUser.uid;
    const userDoc = doc(this.db.firestore, `users/${userId}`);
    const user = (await getDoc(userDoc)).data() as User;
    this.organisationId = user.organisationRef.id;

    this.form.controls.accepted.valueChanges.subscribe((value) => {
      this.accepted = value;
      if (value) {
        this.form.removeControl('reasonOfDenial');
      } else {
        this.form.addControl(
          'reasonOfDenial',
          new FormControl(null, [Validators.required])
        );
        this.form.controls.reasonOfDenial.setErrors({ invalid: true });
      }
    });
  }

  moderatePost() {
    setDoc(
      doc(
        this.db.firestore,
        `organisations/${this.organisationId}/bulletinBoard/${this.data.userId}`
      ),
      {
        moderated: true,
        reasonOfDenial: this.form.value.reasonOfDenial ?? null,
        acceptedDate: new Date(),
        accepted: this.form.value.accepted,
      },
      { merge: true }
    );
  }
}
