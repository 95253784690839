import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';

import * as XLSX from 'xlsx';

import {
  AngularFirestore,
  AngularFirestoreCollection,
  AngularFirestoreDocument,
  DocumentReference,
} from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { MatDialog } from '@angular/material/dialog';

import {
  Observable,
  Subject,
  Subscription,
  combineLatest,
  ReplaySubject,
} from 'rxjs';
import {
  map,
  first,
  take,
  debounceTime,
  distinctUntilChanged,
} from 'rxjs/operators';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { ImportExportComponent } from '../import-export/import-export.component';
import { EditVolunteerComponent } from './edit-volunteer/edit-volunteer.component';

export interface Volunteer {
  name: string;
  phone: string;
  fullName?: string;
  group?: string;
  comment?: string;
  hasGivenPermission?: boolean;
  tags?: any;
}

export interface VolunteerId extends Volunteer {
  id: string;
}
export interface Tag {
  name: string;
  color: string;
  tagQuestion?: string;
}

export interface TagId extends Tag {
  id: string;
}

export interface UserTag {
  preference: boolean;
}
export interface UserTagId extends UserTag {
  id: string;
}

export interface User {
  name?: string;
  organisationRef?: DocumentReference;
  email?: string;
  id?: string;
  organisationId?: string;
}

@Component({
  selector: 'app-volunteers',
  templateUrl: './volunteers.component.html',
  styleUrls: ['./volunteers.component.scss'],
})
export class VolunteersComponent implements OnInit {
  @ViewChild('uploader', { static: false }) uploader: ElementRef;
  fileUploaded: File;
  worksheet: any;
  storeData: any;
  importedData: Subject<VolunteerId[]> = new ReplaySubject<VolunteerId[]>();
  volunteers: Observable<VolunteerId[]>;
  public filteredVolunteers: ReplaySubject<VolunteerId[]> = new ReplaySubject<
    VolunteerId[]
  >(1);
  user: Observable<User>;
  disabled: boolean;
  header = true;
  volunteersCollection: AngularFirestoreCollection<Volunteer>;
  searchQuery: string;
  searchQueryChanged: Subject<string> = new Subject<string>();
  totalVolunteers: number;
  tagsCollection: AngularFirestoreCollection<Tag>;
  tags: Observable<TagId[]>;
  tagsObj: any;
  userTagsCollection: AngularFirestoreCollection<UserTag>;
  userTags: Observable<UserTagId[]>;
  organisationId: string;
  constructor(
    public afs: AngularFirestore,
    public afAuth: AngularFireAuth,
    private _snackBar: MatSnackBar,
    public dialog: MatDialog
  ) {}

  async ngOnInit() {
    const user = await this.afAuth.currentUser;
    if (user) {
      this.user = this.afs
        .doc<User>('users/' + user.uid)
        .valueChanges()
        .pipe(
          map((userData) => {
            // console.log('User =>', userData);
            if (!userData) {
              userData = {};
            }
            userData.email = user.email;
            userData.id = user.uid;
            // if (userData.organisationRef) {
            //   userData.organisationRef = userData.organisationRef;
            this.organisationId = userData.organisationRef.id;
            // }
            // co
            return userData;
          })
        );
      this.user.subscribe((userData) => {
        console.log('userData', userData);
        this.tagsCollection = this.afs.collection<Tag>(
          'organisations/' + userData.organisationRef.id + '/tags'
        );
        this.tags = this.tagsCollection.snapshotChanges().pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Tag;
              const id = a.payload.doc.id;
              return { id, ...data };
            })
          )
        );
        this.tags.subscribe((value) => {
          const tagsObj = {};
          value.forEach((val) => {
            tagsObj[val.id] = val;
          });
          this.tagsObj = tagsObj;
        });
        this.volunteersCollection = this.afs.collection<Volunteer>(
          'organisations/' + userData.organisationRef.id + '/volunteers',
          (ref) => ref.orderBy('name', 'asc')
        );
        this.volunteers = this.volunteersCollection.snapshotChanges().pipe(
          map((actions) =>
            actions.map((a) => {
              const data = a.payload.doc.data() as Volunteer;
              const id = a.payload.doc.id;
              return { id, ...data };
            })
          )
        );
        this.volunteers.subscribe((value) => {
          console.log('this.volunteers', value);
        });
        const combinedObservable = combineLatest(
          this.volunteers,
          this.searchQueryChanged
        );
        combinedObservable
          .pipe(debounceTime(300), distinctUntilChanged())
          .subscribe((values) => {
            console.log('values', values);
            const volunteer = values[0];
            // const searchQuery = values[1];

            const filteredVolunteers = volunteer.filter((item) =>
              this.checkFilters(item)
            );
            console.log('filteredVolunteers', filteredVolunteers);
            this.totalVolunteers = filteredVolunteers.length;
            this.filteredVolunteers.next(filteredVolunteers);
            // this.filteredUsers.next(this.allUsers.pipe(map(items => items.filter(item => this.checkFilters(item)))));
          });
        this.filteredVolunteers.subscribe((value) => {
          value.forEach((val) => {
            this.userTagsCollection = this.afs.collection<UserTag>(
              'organisations/' +
                userData.organisationRef.id +
                '/volunteers/' +
                val.id +
                '/tags'
            );
            this.userTags = this.userTagsCollection.snapshotChanges().pipe(
              map((actions) =>
                actions.map((a) => {
                  const data = a.payload.doc.data() as UserTag;
                  const id = a.payload.doc.id;
                  return { id, ...data };
                })
              )
            );
            this.userTags.subscribe((tags) => {
              const userTags = [];
              tags.forEach((tag) => {
                if (tag.preference === true) {
                  for (const tagObj in this.tagsObj) {
                    if (this.tagsObj[tagObj].id === tag.id) {
                      userTags.push(this.tagsObj[tagObj]);
                    }
                  }
                }
              });
              val.tags = userTags;
            });
          });
        });
        this.searchQueryChanged.next('');
      });
    }
  }
  onFilterChange(type, ev?, id?) {
    if (type === 'search') {
      this.searchQueryChanged.next(ev);
    }
  }

  checkFilters(volunteer) {
    console.log('volunteer', volunteer);
    let passesSearchFilter = true;
    if (this.searchQuery) {
      passesSearchFilter = false;
      if (volunteer.name) {
        if (
          volunteer.name.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
      if (volunteer.fullname) {
        if (
          volunteer.fullname
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
      if (volunteer.phone) {
        if (
          volunteer.phone.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
      if (volunteer.comment) {
        if (
          volunteer.comment
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
      if (volunteer.group) {
        if (
          volunteer.group.toLowerCase().includes(this.searchQuery.toLowerCase())
        ) {
          passesSearchFilter = true;
        }
      }
      if (volunteer.tags) {
        volunteer.tags.forEach((tag) => {
          if (tag.name.toLowerCase().includes(this.searchQuery.toLowerCase())) {
            passesSearchFilter = true;
          }
        });
      }
    }

    if (passesSearchFilter) {
      // console.log('user passes filter:', user);
      return volunteer;
    }
  }

  fileChangeListener() {
    this.volunteers = this.volunteersCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Volunteer;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    this.tags = this.tagsCollection.snapshotChanges().pipe(
      map((actions) =>
        actions.map((a) => {
          const data = a.payload.doc.data() as Tag;
          const id = a.payload.doc.id;
          return { id, ...data };
        })
      )
    );
    const combinedObservable = combineLatest(
      this.volunteers,
      this.importedData,
      this.tags
    );
    combinedObservable
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((res: [VolunteerId[], Array<any>, TagId[]]) => {
        console.log('res', res);
        const existingVolunteers = res[0];
        const importedVolunteers = res[1];
        const existingTags = res[2];
        if (importedVolunteers === null) {
          return;
        }
        // check for existingVolunteers with importedVolunteers' data, update their info.
        // create new entry for all newly imported volunteers, process account creation in functions trigger.
        importedVolunteers.forEach(async (volunteerData) => {
          console.log('volunteerData', volunteerData);
          let name;
          let fullName;
          let comment;
          let group;
          // tslint:disable-next-line: prefer-const
          let hasGivenPermission;
          let phone;
          let tags;
          let tagsObj = [];
          // this section looks for a recognized column title and matches it with the correct userData
          if (volunteerData.name) {
            name = volunteerData.name;
          } else {
            name = '';
          }
          if (volunteerData.fullName) {
            fullName = volunteerData.fullName;
          } else {
            fullName = '';
          }
          if (volunteerData.comment) {
            comment = volunteerData.comment;
          } else {
            comment = '';
          }
          if (volunteerData.group) {
            group = volunteerData.group;
          } else {
            group = '';
          }
          if (volunteerData.hasGivenPermission) {
            hasGivenPermission = volunteerData.hasGivenPermission;
          } else {
            hasGivenPermission = null;
          }
          if (volunteerData.phone) {
            phone = volunteerData.phone;
          }
          if (volunteerData.tags) {
            tags = volunteerData.tags.split(', ');
            console.log('tagsBefore', tags);
            console.log('existingTags', existingTags);
            tags.forEach((tag) => {
              for (let existingTag in existingTags) {
                if (
                  existingTags[existingTag].name
                    .toLowerCase()
                    .includes(tag.toLowerCase())
                ) {
                  let tagObj = {
                    id: existingTags[existingTag].id,
                    preference: true,
                  };
                  tagsObj.push(tagObj);
                }
              }
            });
          }
          const importData: Volunteer = {
            name,
            fullName,
            comment,
            group,
            hasGivenPermission,
            phone,
          };
          //
          const existingData = existingVolunteers.find((element) => {
            return element.phone === importData.phone;
          });
          console.log('tagsObj', tagsObj);
          console.log('existingData', existingData);
          if (existingData) {
            // update existing doc

            await this.volunteersCollection
              .doc(existingData.id)
              .set(importData, { merge: true });
            if (tagsObj) {
              tagsObj.forEach((tag) => {
                this.userTagsCollection = this.afs.collection<UserTag>(
                  'organisations/' +
                    this.organisationId +
                    '/volunteers/' +
                    existingData.id +
                    '/tags'
                );
                this.userTagsCollection
                  .doc(tag.id)
                  .set({ preference: tag.preference }, { merge: true });
              });
            }
          } else {
            // create new doc
            const docId = this.afs.createId();
            console.log('importData', importData);
            await this.volunteersCollection.doc(docId).set(importData);
            if (tagsObj) {
              tagsObj.forEach((tag) => {
                this.userTagsCollection = this.afs.collection<UserTag>(
                  'organisations/' +
                    this.organisationId +
                    '/volunteers/' +
                    docId +
                    '/tags'
                );
                this.userTagsCollection
                  .doc(tag.id)
                  .set({ preference: tag.preference }, { merge: true });
              });
            }
          }
          this.importedData.next(null);
        });

        this._snackBar.open('De deelnemers zijn geimporteerd', '', {
          duration: 5000,
        });
      });
  }

  editVolunteer(volunteer?) {
    const dialogRef = this.dialog.open(EditVolunteerComponent, {
      width: '400px',
      data: { volunteersCollection: this.volunteersCollection, volunteer },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      console.log('The dialog was closed with result:', result);
      if (result) {
      }
    });
  }

  deleteVolunteer(volunteer, orgRefId) {
    console.log('delete tag', volunteer);
    console.log('orgRefId', orgRefId);
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Deelnemer verwijderen',
        text: `Weet je zeker dat je deelnemer ${volunteer.name} wil verwijderen?`,
      },
    });

    dialogRef.afterClosed().subscribe(async (result) => {
      const promises = [];
      if (result) {
        promises.push(
          await this.afs
            .doc<Volunteer>(
              `organisations/${orgRefId}/volunteers/${volunteer.id}`
            )
            .delete()
        );

        this.afs
          .collection<Tag>(
            `organisations/${orgRefId}/volunteers/${volunteer.id}/tags`
          )
          .get()
          .subscribe((collection) => {
            collection.docs.forEach(async (doc) => {
              promises.push(
                await this.afs
                  .doc(
                    `organisations/${orgRefId}/volunteers/${volunteer.id}/tags/${doc.id}`
                  )
                  .delete()
              );
            });
          });

        this.afs
          .collection<Tag>(
            `organisations/${orgRefId}/volunteers/${volunteer.id}/replies`
          )
          .get()
          .subscribe((collection) => {
            collection.docs.forEach(async (doc) => {
              promises.push(
                await this.afs
                  .doc(
                    `organisations/${orgRefId}/volunteers/${volunteer.id}/replies/${doc.id}`
                  )
                  .delete()
              );
            });
          });

        await Promise.all(promises);
        this._snackBar.open('Deelnemer verwijderd', '', {
          duration: 5000,
        });
      }
    });
  }
  uploadedFile(event) {
    console.log('event', event);
    console.log('uploading file');
    this.fileUploaded = event.target.files[0];
    this.readExcel();
  }
  openImportExport() {
    const dialogRef = this.dialog.open(ImportExportComponent, {
      width: '300px',
    });
    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      if (result === 'Export') {
        this.exportTableToExcel();
      }
      if (result === 'Import') {
        this.uploader.nativeElement.click();
      }
    });
  }
  readExcel() {
    try {
      const readFile = new FileReader();
      const spreadsheet = {};
      readFile.onload = (e) => {
        try {
          this.storeData = readFile.result;
          const data = new Uint8Array(this.storeData);
          const arr = new Array();
          for (let i = 0; i !== data.length; ++i) {
            arr[i] = String.fromCharCode(data[i]);
          }
          const bstr = arr.join('');
          const workbook = XLSX.read(bstr, { type: 'binary' });
          const firstSheetName = workbook.SheetNames[0];
          this.worksheet = workbook.Sheets[firstSheetName];

          Object.keys(this.worksheet).forEach((key) => {
            if (key !== '!ref' && key !== '!margins') {
              const rowId = key.match(/\d+/g).toString();
              const colId = key.match(/[a-zA-Z]+/g).toString();
              if (!spreadsheet[rowId]) {
                spreadsheet[rowId] = {};
              }
              spreadsheet[rowId][colId] = this.worksheet[key].w;
            }
          });
          const columnNames = spreadsheet[1];
          Object.keys(columnNames).forEach((key) => {
            key = key;
            const val = columnNames[key].toLowerCase();
            switch (val) {
              default:
                delete columnNames[key];
                break;
              case 'naam':
                columnNames[key] = 'name';
                break;
              case 'volledige naam':
                columnNames[key] = 'fullName';
                break;
              case 'opmerking':
                columnNames[key] = 'comment';
                break;
              case 'groep':
                columnNames[key] = 'group';
                break;
              case 'toestemming gegeven':
                columnNames[key] = 'hasGivenPermission';
                break;
              case 'telefoon nummer':
                columnNames[key] = 'phone';
                break;
              case 'tags':
                columnNames[key] = 'tags';
                break;
            }
          });
          delete spreadsheet[1];
          const importedData = [];
          Object.keys(spreadsheet).forEach((key) => {
            const rowObj = {};
            Object.keys(spreadsheet[key]).forEach((colKey) => {
              const colName = columnNames[colKey];
              // console.log(spreadsheet[key][colKey]);
              // console.log('colName', colName);
              if (colName) {
                if (colName === 'name') {
                  spreadsheet[key][colKey] = spreadsheet[key][colKey];
                }
                if (colName === 'fullName') {
                  spreadsheet[key][colKey] = spreadsheet[key][colKey];
                }
                if (colName === 'comment') {
                  spreadsheet[key][colKey] = spreadsheet[key][colKey];
                }
                if (colName === 'group') {
                  spreadsheet[key][colKey] = spreadsheet[key][colKey];
                }
                if (colName === 'hasGivenPermission') {
                  spreadsheet[key][colKey] = Boolean(spreadsheet[key][colKey]);
                }
                if (colName === 'phone') {
                  spreadsheet[key][colKey] = spreadsheet[key][colKey];
                }
                if (colName === 'tags') {
                  spreadsheet[key][colKey] = spreadsheet[key][colKey];
                }
                rowObj[colName] = spreadsheet[key][colKey];
              }
            });
            console.log('rowObj', rowObj);
            importedData.push(rowObj);
          });
          console.log('importedData', importedData);
          this.importedData.next(importedData);
          this.fileChangeListener();
        } catch (err) {
          console.error(err);
          this._snackBar.open(
            'fout bij inladen bestand, controleer gegevens en probeer opnieuw.',
            '',
            {
              duration: 5000,
            }
          );
          throw err;
        }
      };
      readFile.readAsArrayBuffer(this.fileUploaded);
    } catch (err) {
      this._snackBar.open(
        'onbekende fout, controleer bestand en probeer opnieuw.',
        '',
        {
          duration: 5000,
        }
      );
      throw err;
    }
  }
  async exportTableToExcel() {
    // const volunteersRef = this.afs.collection<Volunteer>('organisations/' + userData.organisationRef.id + '/volunteers');
    const volunteersArray = [];
    // const volunteersObservable = await this.volunteersCollection.get();
    this.filteredVolunteers.forEach((volunteers) => {
      // console.log('vouchers', vouchers);
      volunteers.forEach((volunteerDoc) => {
        // const volunteer = volunteerDoc.data() as Volunteer;
        const exportVehicleObj: any = {};
        let tagString = '';
        exportVehicleObj.Naam = volunteerDoc.name ? volunteerDoc.name : '';
        exportVehicleObj['Volledige naam'] = volunteerDoc.fullName
          ? volunteerDoc.fullName
          : '';
        exportVehicleObj.Opmerking = volunteerDoc.comment
          ? volunteerDoc.comment
          : '';
        exportVehicleObj.Groep = volunteerDoc.group ? volunteerDoc.group : '';
        exportVehicleObj['Toestemming gegeven'] =
          volunteerDoc.hasGivenPermission
            ? volunteerDoc.hasGivenPermission
            : '';
        exportVehicleObj['Telefoon nummer'] = volunteerDoc.phone
          ? volunteerDoc.phone
          : '';
        volunteerDoc.tags.forEach((tag) => {
          if (tagString === '') {
            tagString = tag.name;
          } else {
            tagString = tagString + `, ${tag.name}`;
          }
        });
        exportVehicleObj.Tags = tagString ? tagString : '';
        console.log('exportVehicleObj', exportVehicleObj);
        volunteersArray.push(exportVehicleObj);
      });
    });
    if (volunteersArray.length > 0) {
      console.log('vouchers', volunteersArray);
      const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(volunteersArray); // converts a DOM TABLE element to a worksheet
      const wb: XLSX.WorkBook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, 'Volunteers');

      // /* save to file */
      XLSX.writeFile(wb, 'deelnemers.xlsx');
    } else {
      // Nothing to export
    }
  }
}
